import { useCallback, useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import AppContext from '../AppContext/AppContext';
import TenantContext from '../TenantContext';
import useMutationWithTracing from '../../useMutationWithTracing';
import type { AnalyticsEventsInput } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  AnalyticsMutation,
  AnalyticsMutationVariables,
  EndCurrentParentFrameMutation,
  EndCurrentParentFrameMutationVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import analyticsMutation from './Analytics.mutation.graphql';
import frameEndMutation from './AnalyticsFrameEnd.mutation.graphql';
import { getParentFrameId } from './useFrameEnd';

/**
 * `usePageTracking` calls the Analytics Event for page view.
 *
 * @author Madhusudhana RK
 */
export default function usePageTracking(pageId: EndUserPages | string) {
  const { sameSite } = useContext(TenantContext);
  const { contextNode, contextMessage } = useContext(AppContext);
  const parentFrameId = getParentFrameId();

  /** Aurora analytics mutation graphql */
  const [analytics] = useMutationWithTracing<AnalyticsMutation, AnalyticsMutationVariables>(
    module,
    analyticsMutation
  );

  const [frameEnd] = useMutationWithTracing<
    EndCurrentParentFrameMutation,
    EndCurrentParentFrameMutationVariables
  >(module, frameEndMutation, {
    variables: { uuid: parentFrameId },
    context: { parentFrameId },
    onCompleted: () => getParentFrameId(uuid())
  });

  const callToAnalytics = useCallback(async (): Promise<void> => {
    // analytics mutation only works where aurora is on same host as LIA
    const updatedFrameId = getParentFrameId();
    if (sameSite) {
      const eventsInput: AnalyticsEventsInput = {
        pageView: {
          name: pageId,
          url: window.location.href
        }
      };

      if (contextMessage?.id) {
        eventsInput.domainContext = { target: { id: contextMessage.id } };
        eventsInput.messages = [{ target: { id: contextMessage.id } }];
      } else if (contextNode?.id) {
        // if private community is enabled, the context node has no id for anonymous users
        eventsInput.domainContext = { target: { id: contextNode.id } };
      }

      //Call Analytics after FrameEnd is called with fresh uuid
      await analytics({
        context: {
          parentFrameId: updatedFrameId
        },
        variables: {
          eventsInput
        }
      }).finally(() => getParentFrameId(uuid()));
    }
  }, [analytics, pageId, contextNode, contextMessage, sameSite]);

  // Execute FrameEnd if user unload the component by any means
  useEffect(() => {
    const listener = () => {
      const currentFrameId = getParentFrameId();
      frameEnd({
        variables: { uuid: currentFrameId },
        context: { parentFrameId: currentFrameId }
      });
    };
    window.addEventListener('beforeunload', listener);

    return (): void => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [frameEnd]);

  //Call only when new page viewed. Do nothing on tab switch and dynamic url update.
  useEffect(() => {
    callToAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
