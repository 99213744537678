import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import type { ParsedUrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import { getLog } from '@aurora/shared-utils/log';
import type React from 'react';
import { useCallback, useEffect, useId, useRef } from 'react';
import useTranslation from '../../useTranslation';
import type { FeedbackDetails } from './ActionFeedbackHelper';
import { getActionFeedbackVariant, isValidFeedback } from './ActionFeedbackHelper';
import ActionFeedbacks from './ActionFeedbackTypes';

const log = getLog(module);

/**
 * Display the toast alert based on query Parameter
 *
 * @author Amit Agrawal, Adam Ayres
 */
const ActionFeedback: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.ACTION_FEEDBACK);
  const uid = useId();
  const { addToast } = useToasts();
  const { router } = useEndUserRoutes();
  const actionIdString = router.getUnwrappedQueryParam(EndUserQueryParams.ACTION_FEEDBACK, '');
  const actionId = getAsEnum(actionIdString, ActionFeedbacks);
  const isRemovingQueryParams = useRef<boolean>(false);

  /**
   * Return the toast message for the action feedback
   *
   * @returns the toast message
   */
  const getToastMessage = useCallback(
    (queryParameters: EndUserQueryParams[], query: ParsedUrlQuery, action: ActionFeedbacks) => {
      let keys = null;
      if (queryParameters) {
        // eslint-disable-next-line unicorn/no-array-reduce,unicorn/prefer-object-from-entries
        keys = queryParameters.reduce<Record<string, string | string[]>>(
          (accumulator, parameter) => {
            return {
              ...accumulator,
              [parameter]: query[parameter]
            };
          },
          {}
        );
      }
      return formatMessage(`${action}.message`, keys);
    },
    [formatMessage]
  );

  /**
   * Stores the parsed URL query to the components local state so that it can be used later
   *
   * Also, removes the action feedback related query parameters from the URL (shallowly)
   * when the component renders for the first time (componentDidMount)
   */
  useEffect(() => {
    if (
      !isRemovingQueryParams.current &&
      actionId &&
      !textLoading &&
      isValidFeedback(actionId, router.query)
    ) {
      isRemovingQueryParams.current = true;
      const { toastVariant, alertVariant, queryParameters, autohide }: FeedbackDetails =
        getActionFeedbackVariant(actionId);
      let parametersToRemove = [EndUserQueryParams.ACTION_FEEDBACK, EndUserQueryParams.TIMESTAMP];
      if (queryParameters) {
        parametersToRemove = [...parametersToRemove, ...queryParameters];
      }
      const message = getToastMessage(queryParameters, router.query, actionId);
      router
        .removeQueryParam(parametersToRemove)
        .then(() => {
          return addToast({
            id: `action-feedback-${uid}`,
            alertVariant,
            message,
            title: formatMessage(`${actionId}.title`),
            toastVariant,
            autohide: autohide !== false,
            delay: 4000
          });
        })
        .finally(() => {
          isRemovingQueryParams.current = false;
        })
        .catch(error => {
          log.error(error, 'Unable to remove query params: %s', parametersToRemove);
        });
    }
  }, [actionId, addToast, formatMessage, getToastMessage, router, textLoading, uid]);

  return null;
};

export default ActionFeedback;
